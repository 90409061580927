<template>
    <div class="">
        <mdb-row>
            <mdb-col class="mt-2"
                     md="4"
                     xl="3">
                <mdb-card>
                    <!--Card Data-->
                    <mdb-row class="mt-3">
                        <mdb-col md="5"
                                 col="5"
                                 class=" text-left pl-4">
                            <mdb-btn tag="a"
                                     floating
                                     size="lg"
                                     color="default"
                                     @click="$router.push('/orders/add_order')"
                                     class="ml-4"><i class="fas fa-shopping-cart"></i>
                            </mdb-btn>
                        </mdb-col>
                        <mdb-col md="7"
                                 col="7"
                                 class=" text-right pr-5">
                            <h5 class="mt-4 mb-2 font-weight-bold">維修/銷售</h5>
                            <p class="font-small grey-text"
                               style="height:1.5em">&nbsp;</p>
                        </mdb-col>
                    </mdb-row>
                    <!--/.Card Data-->
                </mdb-card>
                <!--/.Card-->
            </mdb-col>
            <mdb-col class="mt-2"
                     md="4"
                     xl="3">
                <mdb-card>
                    <!--Card Data-->
                    <mdb-row class="mt-3">
                        <mdb-col md="5"
                                 col="5"
                                 class=" text-left pl-4">
                            <mdb-btn tag="a"
                                     floating
                                     size="lg"
                                     color="primary"
                                     @click="$router.push('/product')"
                                     class="ml-4">
                                <i class="fas fa-lightbulb"></i>
                            </mdb-btn>
                        </mdb-col>

                        <mdb-col md="7"
                                 col="7"
                                 class=" text-right pr-5">
                            <h5 class="ml-4 mt-4 mb-2 font-weight-bold">{{$numeral(product_number).format("0,0")}}</h5>
                            <p class="font-small grey-text">商品</p>
                        </mdb-col>
                    </mdb-row>
                    <!--/.Card Data-->
                </mdb-card>
                <!--/.Card-->
            </mdb-col>
            <mdb-col class="mt-2"
                     md="4"
                     xl="3">
                <mdb-card>
                    <!--Card Data-->
                    <mdb-row class="mt-3">
                        <mdb-col md="5"
                                 col="5"
                                 class=" text-left pl-4">
                            <mdb-btn tag="a"
                                     floating
                                     size="lg"
                                     color="default"
                                     @click="$router.push('/customer')"
                                     class="ml-4">
                                <i class="fas fa-users"></i>
                            </mdb-btn>
                        </mdb-col>
                        <mdb-col md="7"
                                 col="7"
                                 class=" text-right pr-5">
                            <h5 class="ml-4 mt-4 mb-2 font-weight-bold">{{$numeral(customer_number).format("0,0")}}</h5>
                            <p class="font-small grey-text">來客數</p>
                        </mdb-col>
                    </mdb-row>
                    <!--/.Card Data-->
                </mdb-card>
                <!--/.Card-->
            </mdb-col>
            <mdb-col class="mt-2"
                     md="4"
                     xl="3">
                <mdb-card>
                    <!--Card Data-->
                    <mdb-row class="mt-3">
                        <mdb-col md="5"
                                 col="5"
                                 class=" text-left pl-4">
                            <mdb-btn tag="a"
                                     floating
                                     size="lg"
                                     color="dark"
                                     @click="switch_punch()"
                                     class="ml-4">
                                <i :class="punching ? 'fa fa-spin fa-sync' : 'fas fa-business-time'"></i>
                            </mdb-btn>
                        </mdb-col>
                        <mdb-col md="7"
                                 col="7"
                                 class="mb-3 text-right pr-5">
                            <h5 class="mt-2 mb-2 font-weight-bold">{{ punchList.length==0 || punchList[0].start=="" ? '上班' : '下班' }}刷卡</h5>
                            <p class="font-small grey-text"
                               style="height:1.5em">
                                <span v-if="punchList.length>0">
                                    {{ punchList[0].start!=null && punchList[0].start!='' ? `上班：${punchList[0].start}` : '' }}<br>
                                    {{ punchList[0].end!=null && punchList[0].end!='' ? `下班：${punchList[0].end}` : '' }}
                                </span>
                            </p>
                        </mdb-col>
                    </mdb-row>
                    <!--/.Card Data-->
                </mdb-card>
                <!--/.Card-->
            </mdb-col>
        </mdb-row>
        <section class="mt-5">
            <!--Card-->
            <mdb-card cascade
                      narrow>
                <!--Section: Chart-->
                <section>
                    <!--Grid row-->
                    <mdb-row>
                        <!--Grid column-->
                        <mdb-col xl="5"
                                 md="12"
                                 class="mr-0">
                            <!--Card image-->
                            <div class="view view-cascade gradient-card-header light-blue lighten-1">
                                <h4 class="h4-responsive mb-0 font-weight-bold">營業狀況</h4>
                            </div>
                            <!--/Card image-->

                            <!--Card content-->
                            <mdb-card-body cascade
                                           class="pb-5">
                                <!--Panel data-->
                                <div class="mb-3">
                                    <label>類型</label><br>
                                    <mdb-btn-group>
                                        <mdb-btn class="py-1 px-2"
                                                 size="sm"
                                                 :color="order.show_type=='income'?'secondary':''"
                                                 :outline="order.show_type!='income'?'secondary':''"
                                                 @click="order.show_type='income'">營業額</mdb-btn>
                                        <mdb-btn class="py-1 px-2"
                                                 size="sm"
                                                 :color="order.show_type=='customer'?'secondary':''"
                                                 :outline="order.show_type!='customer'?'secondary':''"
                                                 @click="order.show_type='customer'">來客數</mdb-btn>
                                    </mdb-btn-group>
                                </div>
                                <div class="mb-3">
                                    <label>時間</label><br>
                                    <mdb-btn-group>
                                        <mdb-btn class="py-1 px-2"
                                                 size="sm"
                                                 :color="order.range==7?'secondary':''"
                                                 :outline="order.range!=7?'secondary':''"
                                                 @click="set_date_range(7)">
                                            7日
                                        </mdb-btn>
                                        <mdb-btn class="py-1 px-2"
                                                 size="sm"
                                                 :color="order.range==15?'secondary':''"
                                                 :outline="order.range!=15?'secondary':''"
                                                 @click="set_date_range(15)">
                                            15日
                                        </mdb-btn>
                                        <mdb-btn class="py-1 px-2"
                                                 size="sm"
                                                 :color="order.range==30?'secondary':''"
                                                 :outline="order.range!=30?'secondary':''"
                                                 @click="set_date_range(30)">
                                            30日
                                        </mdb-btn>
                                        <mdb-btn class="py-1 px-2"
                                                 size="sm"
                                                 :color="order.range==0?'secondary':''"
                                                 :outline="order.range!=0?'secondary':''"
                                                 @click="set_date_range(0)">
                                            其他
                                        </mdb-btn>
                                    </mdb-btn-group>
                                    <mdb-row v-show="order.range==0">
                                        <mdb-col>
                                            <datepicker label="日期(起)"
                                                        v-model="order.start"></datepicker>
                                        </mdb-col>
                                        <mdb-col>
                                            <datepicker label="日期(訖)"
                                                        v-model="order.end"
                                                        :min="order.start"></datepicker>
                                        </mdb-col>
                                    </mdb-row>
                                </div>
                                <div class="mb-">
                                    <mdb-switch offLabel="顯示方式"
                                                :onLabel="order.showchart?'圖表':'表格'"
                                                :checked="order.showchart"
                                                @getValue="val=>order.showchart=val"></mdb-switch>
                                </div>
                                <!--/Panel data-->
                            </mdb-card-body>
                            <!--/.Card content-->
                        </mdb-col>
                        <!--Grid column-->

                        <!--Grid column-->
                        <mdb-col xl="7"
                                 md="12"
                                 class="mb-4">
                            <!--Card image-->
                            <div class="view view-cascade gradient-card-header white">
                                <!-- Chart -->
                                <mdb-line-chart :data="get_line_chart"
                                                :options="lineChartOptions"
                                                :height="300"
                                                v-if="order.showchart"></mdb-line-chart>
                                <div style="color:#333">
                                    <table class="table table-bordered"
                                           v-show="!order.showchart">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th style="width:30%"
                                                    v-for="s in get_line_chart.datasets"
                                                    :key="s.label">{{s.label}}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(l,lk) in get_line_chart.labels"
                                                :key="l">
                                                <td>{{l}}</td>
                                                <th class="text-right pr-5"
                                                    v-for="s in get_line_chart.datasets"
                                                    :key="s.label">{{$numeral(s.data[lk]).format('0,0')}}</th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!--/Card image-->
                        </mdb-col>
                        <!--Grid column-->
                    </mdb-row>
                    <!--Grid row-->
                </section>
                <!--Section: Chart-->
            </mdb-card>
            <!--/.Card-->
        </section>
    </div>
</template>

<script>
import {
  mdbRow,
  mdbCol,
  mdbCard,
  mdbCardBody,
  mdbBtn,
  mdbSwitch,
  mdbBtnGroup,
  mdbLineChart,
} from "mdbvue";
import datepicker from "../components/datepicker";
let numeral = require("numeral");
export default {
  components: {
    mdbRow,
    mdbCol,
    mdbCard,
    mdbCardBody,
    mdbBtn,
    mdbSwitch,
    mdbBtnGroup,
    mdbLineChart,
    datepicker,
  },
  data() {
    return {
      product_number: 0,
      customer_number: 0,
      storeList: [],
      order: {
        range: 7,
        start: "",
        end: "",
        show_type: "income",
        showchart: false,
      },
      orderList: [],
      lineChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: true,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: function (value) {
                  if (value > 100) {
                    value = Math.round(value / 100) * 100;
                  }
                  return numeral(value).format("0,0");
                },
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
        tooltips: {
          position: "average",
          intersect: false,
          enabled: true,
          mode: "index",
          callbacks: {
            label(TooltipItem, data) {
              return (
                data.datasets[TooltipItem.datasetIndex].label +
                ":" +
                numeral(TooltipItem.value).format("0,0")
              );
            },
          },
        },
      },
      punchList: [],
      punching: false,
    };
  },
  mounted() {
    console.clear();
    this.get_data();
    this.set_date_range(7);
  },
  watch: {
    order: {
      handler() {
        this.get_order_data();
      },
      deep: true,
    },
  },
  computed: {
    storeKeyList() {
      let output = {};
      this.storeList.forEach((item) => {
        output[item.id] = item;
      });
      return output;
    },
    get_line_chart() {
      let vue = this,
        stores = [],
        storeTotal = [],
        labels = [],
        datasets = [],
        i = 0,
        day_len = vue.$moment(vue.order.end).diff(vue.order.start, "days") + 1,
        backgroundColor = [
          "rgba(100, 150, 250, 0.2)",
          "rgba(120, 200, 120, 0.2)",
          "rgba(200, 120, 200, 0.2)",
        ],
        borderColor = [
          "rgba(100, 150, 250, 1)",
          "rgba(120, 200, 120, 1)",
          "rgba(200, 120, 200, 1)",
        ];
      let date = vue.$moment(vue.order.start);
      for (let d = 0; d < day_len; d++) {
        labels.push(date.format("YYYY-MM-DD"));
        date.add(1, "days");
      }
      vue.storeList.forEach((store) => {
        let data = {
          label: store.name,
          backgroundColor: "",
          borderColor: "",
          fill: false,
          borderWidth: 1,
          data: Array(day_len).fill(0),
          pointRadius: 5,
          lineTension: 0,
          id_store: store.id,
        };
        // vue.orderList
        data.backgroundColor = backgroundColor[i];
        data.borderColor = borderColor[i];
        storeTotal.push(0);
        stores.push(store.id);
        datasets.push(data);
        i++;
      });
      vue.orderList.forEach((item) => {
        let s_idx = stores.indexOf(item.id_store),
          l_idx = labels.indexOf(item.day);
        if (l_idx == -1) {
          l_idx = labels.length;
        }
        let num = vue.order.show_type == "customer" ? 1 : parseInt(item.total);
        datasets[s_idx].data[l_idx] += num;
        storeTotal[s_idx] += num;
      });
      labels = labels.map((day) => {
        day = vue.$moment(day).format("MM/DD");
        return day;
      });
      // 顯示表格的話就增加總計
      if (!vue.order.showchart) {
        labels.push("總計");
        for (let d in datasets) {
          let s_idx = stores.indexOf(datasets[d].id_store);
          datasets[d].data.push(storeTotal[s_idx]);
        }
      }
      return {
        labels: labels,
        datasets: datasets,
        vue: vue,
      };
    },
  },
  methods: {
    // 取出資料
    get_data() {
      let vue = this,
        query = [];
      query = [
        {
          name: "product",
          num: "",
          data: {
            active: {
              type: 0,
              value: "1",
            },
          },
        },
        { name: "customer", num: "", data: {} },

        {
          name: "store",
          data: {},
          sort: {
            name: 0,
          },
        },
        {
          name: "punch",
          data: {
            id_admin: {
              type: 0,
              value: vue.$store.state.user.id,
            },
            day: {
              type: 0,
              value: vue.$moment().format("YYYY-MM-DD"),
            },
          },
        },
      ];
      vue.$store
        .dispatch("get_form", {
          payload: {
            url: `data/get_data/?query=${JSON.stringify(query)}`,
          },
        })
        .then((res) => {
          vue.product_number = res.data.productNumber;
          vue.customer_number = res.data.customerNumber;
          vue.storeList = res.data.storeList;
          vue.punchList = res.data.punchList;
        });
    },
    get_order_data() {
      let vue = this,
        query = [
          {
            name: "orders",
            data: {
              day: {
                type: 7,
                value: [vue.order.start, vue.order.end],
              },
              status: {
                type: 0,
                value: "0",
              },
            },
            sort: { day: 0 },
          },
        ];
      vue.$store
        .dispatch("get_form", {
          payload: {
            url: `data/get_data/?query=${JSON.stringify(query)}`,
          },
        })
        .then((res) => {
          vue.orderList = res.data.ordersList;
        });
    },
    // 設定顯示天數
    set_date_range(d) {
      let vue = this;
      vue.order.range = d;
      vue.order.start = vue.$moment().subtract(d, "days").format("YYYY-MM-DD");
      vue.order.end = vue.$moment().format("YYYY-MM-DD");
      vue.get_order_data();
    },
    // 寫入刷卡資料
    switch_punch() {
      console.log("switch_punch");
      let vue = this,
        id_punch = "";
      vue.$swal
        .fire({
          title: "請再次輸入密碼",
          input: "password",
          showCancelButton: true,
          showLoaderOnConfirm: true,
          preConfirm(val) {
            if (val) {
              if (vue.punchList.length > 0) {
                id_punch = vue.punchList[0].id;
              }
              vue.punching = true;
              return vue.$store
                .dispatch("post_form", {
                  payload: {
                    url: `index/punch/`,
                    data: {
                      pw: val,
                      id_punch: id_punch,
                      id_store: vue.$store.state.id_store,
                    },
                  },
                })
                .then((res) => {
                  if (res.data.status == "ok") {
                    if (vue.punchList.length == 0) {
                      vue.punchList.push(res.data.punch);
                    } else {
                      vue.punchList[0].start = res.data.punch.start;
                      vue.punchList[0].end = res.data.punch.end;
                    }
                  } else {
                    throw new Error("");
                  }
                })
                .catch((error) => {
                  console.log(error);
                  vue.$swal.showValidationMessage(`密碼錯誤`);
                });
            } else {
              vue.$swal.showValidationMessage(`請輸入密碼`);
            }
          },
        })
        .then(() => {
          setTimeout(() => {
            vue.punching = false;
          }, 1000);
        });
    },
  },
};
</script>
